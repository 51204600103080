// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { getEditorMaster, getEditors } from "./editorsMaster.thunk";

const initialState = {
  editorsMasterList: { isFetching: false, data: [], error: null },
  editorsList: { isFetching: false, data: [], error: null },
};

const EditorsMaster = createSlice({
  name: "editorsMaster",
  initialState,
  extraReducers: (builder) => {
    // getEditors
    builder.addCase(getEditors.pending, (state) => {
      state.editorsList.data = [];
      state.editorsList.isFetching = true;
    });

    builder.addCase(getEditors.fulfilled, (state, action) => {
      state.editorsList.isFetching = false;
      state.editorsList.data = action?.payload;
      state.editorsList.error = null;
    });

    builder.addCase(getEditors.rejected, (state, action) => {
      state.editorsList.isFetching = false;
      state.editorsList.error = action?.payload?.message;
    });

    // getEditorMaster
    builder.addCase(getEditorMaster.pending, (state) => {
      state.editorsMasterList.data = [];
      state.editorsMasterList.isFetching = true;
    });

    builder.addCase(getEditorMaster.fulfilled, (state, action) => {
      state.editorsMasterList.isFetching = false;
      state.editorsMasterList.data = action?.payload;
      state.editorsMasterList.error = null;
    });

    builder.addCase(getEditorMaster.rejected, (state, action) => {
      state.editorsMasterList.isFetching = false;
      state.editorsMasterList.error = action?.payload?.message;
    });
  },
});

export default EditorsMaster.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getEditorsList,
  getEditorMasterList,
} from "../../services/EditorsMaster/editorsMaster.services";

export const getEditors = createAsyncThunk(
  "get/editors/list",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getEditorsList();
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEditorMaster = createAsyncThunk(
  "get/editors_master/list",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getEditorMasterList();
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

import { createSlice } from "@reduxjs/toolkit";
import { getPaymentMethod, getUser } from "./user.thunk";

const initialState = {
  isLoading: false,
  paymentFetching: false,
  data: [],
  paymentMethods: [],
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.data = [];
    });

    //payment method
    builder.addCase(getPaymentMethod.pending, (state) => {
      state.paymentFetching = true;
    });
    builder.addCase(getPaymentMethod.fulfilled, (state, action) => {
      state.paymentFetching = false;
      state.paymentMethods = action.payload;
      state.error = null;
    });
    builder.addCase(getPaymentMethod.rejected, (state, action) => {
      state.paymentFetching = false;
      state.error = action.error.message;
      state.paymentMethods = [];
    });
  },
});

export default userSlice.reducer;

// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import {getCaretStatement} from './caretsStatement.thunk'

const initialState = {
  caretsList: { isFetching: false, data: [], error: null },
};

const caretsStatementSlice = createSlice({
  name: "caretStatement",
  initialState,
  extraReducers: (builder) => {
    // styleProfileCreate
    builder.addCase(getCaretStatement.pending, (state) => {
      state.caretsList.data = [];
      state.caretsList.isFetching = true;
    });

    builder.addCase(getCaretStatement.fulfilled, (state, action) => {
      state.caretsList.isFetching = false;
      state.caretsList.data = action?.payload;
      state.caretsList.error = null;
    });

    builder.addCase(getCaretStatement.rejected, (state, action) => {
      state.caretsList.isFetching = false;
      state.caretsList.error = action?.payload?.message;
    });
  },
});

export default caretsStatementSlice.reducer;

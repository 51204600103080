import authHttp from "./authHttp";

// **  Get Current User
export const getUserService = async () => {
  const url = `/api/user/getuser`;
  const { data } = await authHttp.get(url);
  return data;
};

// ** Edit User Info
export const editUserInfo = async (val) => {
  const url = "/api/user/update-user-info";
  const res = await authHttp.post(url, val);
  return res;
};

// ** Edit User Company Detail
export const editUserCompanyInfo = async (val) => {
  const url = "/api/user/update-company-info";
  const res = await authHttp.post(url, val);
  return res;
};

// ** Get User Payment Methods
export const getUserPaymentMethod = async (userId) => {
  const url = `/api/payment-method`;
  const { data } = await authHttp.get(url, { params: { user_id: userId } });
  return data;
};

// ** Make Default payment Method
export const makeDefaultPaymentMethod = async (val) => {
  const url = `/api/payment-method/make-default`;
  const { data } = await authHttp.post(url, val);
  return data;
};

// ** Delete Payment Method
export const deletePaymentMethod = async (val) => {
  const url = `/api/payment-method/destroy/${val.user_payment_method_id}`;
  const { data } = await authHttp.get(url);
  return data;
};

// ** Upload User-Profile
export const updateUserProfile = async (val) => {
  const url = `/api/user/update-image`;
  const { data } = await authHttp.post(url, val);
  return data;
};

// ** Get User-Profile
export const getUserProfile = async (val) => {
  const url = `/api/user/get-image/${val}`;
  const { data } = await authHttp.get(url);
  return data;
};

// ** Get
export const getCountryData = async () => {
  const url = `/api/get-countries`;
  const { data } = await authHttp.get(url);
  return data.data;
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { getArchivedProjectList } from "../../services/ArchivedProjects/archivedProjects.services";

export const getArchivedProjects = createAsyncThunk(
  "get/archivedProject/list",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await getArchivedProjectList(payload);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

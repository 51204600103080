// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** toast
import toast from "react-hot-toast";
import ToastContent from "../../src/views/components/toast/toast";
import { X } from "react-feather";
import { getSubscriptionsPlan } from "../services/subscription.service";

export const fetchPlans = createAsyncThunk(
  "api/getSubscriptionsPlan",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getSubscriptionsPlan();
      return res;
    } catch (error) {
      console.log("error---->>>", error);
      toast((t) => (
        <ToastContent
          t={t.id}
          message={error.response?.data?.message}
          color="danger"
          icon={<X size={12} />}
        />
      ));
      return rejectWithValue(error?.response.data);
    }
  }
);

const initialState = {
  isLoading: false,
  plans: { isFetching: false, data: [], error: null },
  subscription_details: {
    planId: "",
    client_secret: "",
  },
  error: null,
};
const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,

  reducers: {
    subscriptionDetails: (state, action) => {
      state.subscription_details = action.payload;
    },
  },

  extraReducers: (builder) => {
    // fetchPlans
    builder.addCase(fetchPlans.pending, (state) => {
      state.plans.isFetching = true;
    });
    builder.addCase(fetchPlans.fulfilled, (state, action) => {
      state.plans.isFetching = false;
      state.plans.data = action.payload;
      state.plans.error = null;
    });

    builder.addCase(fetchPlans.rejected, (state, action) => {
      state.plans.isFetching = false;
      state.plans.error = action.payload?.message;
    });
  },
});

export const { subscriptionDetails } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;

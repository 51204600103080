import toast from "react-hot-toast";
import ToastContent from "../../views/components/toast/toast";
import { X } from "react-feather";

export const getErrorMsg = (err) => {
  if (!err.response) {
    return toast((t) => (
      <ToastContent
        t={t.id}
        message="Something Went Wrong"
        color="danger"
        icon={<X size={12} />}
      />
    ));
  }
  if (err.response) {
    if (err.response.status === 500) {
      return toast((t) => (
        <ToastContent
          t={t.id}
          message="Something Went Wrong"
          color="danger"
          icon={<X size={12} />}
        />
      ));
    }
    if (err?.response?.status > 400) {
      return toast((t) => (
        <ToastContent
          t={t.id}
          message={err.response.data.message}
          color="danger"
          icon={<X size={12} />}
        />
      ));
    }
  }
};

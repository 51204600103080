import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCaretStatementList } from "../../services/caratsStatementService";

export const getCaretStatement = createAsyncThunk(
  "get/caretStatement/list",
  async (val, { rejectWithValue }) => {
    try {
      const res = await getCaretStatementList(val);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// /api/user/get-current-user

import authHttp from "./authHttp";

// **  Get Current User
export const getCurrentUserService = async () => {
  const url = `/api/user/get-current-user`;
  const { data } = await authHttp.get(url);
  return data;
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUserPaymentMethod,
  getUserService,
} from "../../services/user.services";

export const getUser = createAsyncThunk("get/user", async () => {
  return await getUserService();
});

export const getPaymentMethod = createAsyncThunk(
  "get/PaymentMethod ",
  async (userId) => {
    return await getUserPaymentMethod(userId);
  }
);

import { createAsyncThunk } from "@reduxjs/toolkit";
import { getListDiscount } from "../../services/discountCouponService";
import toast from "react-hot-toast";
import { X } from "react-feather";
import ToastContent from "../../views/components/toast/toast";

export const getDiscountCouponList = createAsyncThunk(
  "get/discount-coupon/list",
  async (val, { rejectWithValue }) => {
    try {
      const res = await getListDiscount(val);
      return res;
    } catch (error) {
      toast((t) => (
        <ToastContent
          t={t.id}
          message={error?.response?.data?.message}
          color="danger"
          icon={<X size={12} />}
        />
      ));
      return rejectWithValue(error?.response?.data);
    }
  }
);

// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { getArchivedProjects } from "./archivedProjects.thunks";

const initialState = {
  archivedProjectsList: { isFetching: false, data: [], error: null },
};

const ArchivedProjectsSlice = createSlice({
  name: "archivedProjects",
  initialState,
  extraReducers: (builder) => {
    // styleProfileCreate
    builder.addCase(getArchivedProjects.pending, (state) => {
      state.archivedProjectsList.data = [];
      state.archivedProjectsList.isFetching = true;
    });

    builder.addCase(getArchivedProjects.fulfilled, (state, action) => {
      state.archivedProjectsList.isFetching = false;
      state.archivedProjectsList.data = action?.payload;
      state.archivedProjectsList.error = null;
    });

    builder.addCase(getArchivedProjects.rejected, (state, action) => {
      state.archivedProjectsList.isFetching = false;
      state.archivedProjectsList.error = action?.payload?.message;
    });
  },
});

export default ArchivedProjectsSlice.reducer;

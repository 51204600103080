import authHttp from "../authHttp";

export const addEditorMaster = async (payload) => {
  const url = `api/editors`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const getEditorsList = async () => {
  const url = "api/get-editors";
  const { data } = await authHttp.get(url);
  return data;
};

export const getEditorMasterList = async () => {
  const url = "api/get-companies-editors";
  const { data } = await authHttp.get(url);
  return data;
};

export const assignProjectEditor = async (payload) => {
  const url = "api/assign-project-editor";
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const assignEditorsMaster = async (payload) => {
  const url = "api/assign-editor";
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const updateEditor = async (payload) => {
  const { id, ...rest } = payload;
  const url = `api/editors/${id}`;
  const { data } = await authHttp.put(url, rest);
  return data;
};

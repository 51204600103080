import authHttp from "../authHttp";

export const getArchivedProjectList = async (payload) => {
  const url = "api/archived-project-list";
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const unarchiveProject = async (payload) => {
  const url = "api/unarchive-project";
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const archivedProject = async (payload) => {
  const url = "api/archive-project";
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const deleteProject = async (payload) => {
  const url = "api/delete-project";
  const { data } = await authHttp.post(url, payload);
  return data;
};
